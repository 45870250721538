.app-background {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #0B5351;
}

.main-content {
  flex: 1;
}

/* About Me Page */
.center-align {
  text-align: center;
}

.name {
  padding-top: 25px;
  padding-bottom: 10px;
  font-family: 'Helvetica', sans-serif;
  font-size: 28px;
  font-weight: bold;
  color: #0B5351;
}

.horizontal-line {
  width: 25%;
  margin: 5px auto;
  border-top: 3px solid #0B5351;
}

.position {
  padding-top: 10px;
  padding-bottom: 25px;
  font-family: 'Helvetica', sans-serif;
  font-size: 20px;
  font-weight: bold;
  color: #0B5351;
}

.text-box {
  flex: 2;
  padding: 10px;
  margin-left: 20px;
}

.hello {
  margin-bottom: 25px;
  font-family: 'Helvetica', sans-serif;
  font-size: 75px;
  font-weight: bold;
  color: #F7FFF7;
}

.button-1 {
  width: 175px;
  padding: 5px;
  margin-right: 20px;
  background-color: #7AB988;
  border: 2px solid #0B5351;
  border-radius: 25px;
  color: white;
  font-family: 'Helvetica', sans-serif;
  font-size: 18px;
  cursor: pointer;
}

.button-1:hover {
  background-color: #0B5351;
  border: 2px solid #7AB988;
}

.button-2 {
  width: 175px;
  padding: 5px;
  background-color: #7AB988;
  border: 2px solid #0B5351;
  border-radius: 25px;
  color: white;
  font-family: 'Helvetica', sans-serif;
  font-size: 18px;
  cursor: pointer;
}

.button-2:hover {
  background-color: #0B5351;
  border: 2px solid #7AB988;
}

.summary {
  margin-top: 45px;
  margin-bottom: 30px;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 18px;
  font-weight: 300;
  color: #FFFFFF;
}

.email-link {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 18px;
  color: #FFFFFF;
}

/* Dev Work Page */
.portfolio-section {
  background-color:#477E7C;
  padding: 10px 75px;
}

.project {
  flex: 1 0 50%;
  margin-bottom: 20px;
}

.projects {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 40px;
  margin-top: 50px;
  margin-bottom: 50px;
}

.projects .card {
  flex: 1 0 100%;
  max-width: 300px;
  min-width: 200px;
  background-color: #0B5351;
  color: white;
  margin-right: 20px;
}

/* Large sceens */
@media (min-width: 768px) {
  .projects .card {
    flex: 1 0 50%;
    max-width: 400px;
    min-width: 300px;
  }
}

/* Even larger screen styles */
@media (min-width: 1024px) {
  .projects .card {
    flex: 1 0 33%;
    max-width: 600px;
    min-width: 400px;
  }
}

.projects .card-img-top {
  width: 100%;
  height: 250px;
  object-fit: cover;
}

.projects .card-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.projects .card-title {
  margin-bottom: 10px;
  font-family: 'Helvetica', sans-serif;
  font-size: 22px;
  font-weight: bold;
  color: #F7FFF7;
}

.projects .card-text {
  min-height: 50px;
  font-family: 'Helvetica', sans-serif;
  font-size: 16px;
  color: #F7FFF7;
  min-height: 50px;
}

.projects .card .btn {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 20px;
}

.projects .btn-repository {
  width: 145px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: #7AB988;
  color: white;
  border: 2px solid #7AB988;
  font-family: 'Helvetica', sans-serif;
  font-size: 16px;
}

.projects .btn-repository:hover {
  background-color: #0B5351;
  border: 2px solid #7AB988;
  color: #7AB988;
}

.projects .btn-project {
  width: 145px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: #7AB988;
  color: white;
  border: 2px solid #7AB988;
  font-family: 'Helvetica', sans-serif;
  font-size: 16px;
}

.projects .btn-project:hover {
  background-color: #0B5351;
  border: 2px solid #7AB988;
  color: #7AB988;
}

/* Contact Page */
.control-label {
  font-family: 'Helvetica', sans-serif;
  font-size: 16px;
  color: white;
}

.submit {
  width: 175px;
  margin-top: 25px;
  padding: 5px;
  background-color: #7AB988;
  border: 2px solid #0B5351;
  border-radius: 25px;
  color: white;
  font-family: 'Helvetica', sans-serif;
  font-size: 18px;
  cursor: pointer;
}

.resume-download {
  margin-bottom: 45px;
  font-size: 18px;
  text-decoration: none;
  color: #7AB988;
}

.proficiency-heading {
  margin-top: 20px;
  font-family: 'Helvetica', sans-serif;
  font-size: 22px;
  font-weight: bold;
  color: white;
}

.proficiency-list {
  margin-left: 20px;
  font-family: 'Helvetica', sans-serif;
  font-size: 16px;
  color: white;
}

/* Style for each proficiency list item */
.proficiency-list li {
  margin: 10px 0;
}
.submit:hover {
  background-color: #0B5351;
  border: 2px solid #7AB988;
}

.Footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
  font-family: 'Helvetica', sans-serif;
  font-size: 9px;
  color: white;
}