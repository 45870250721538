.Header {
  min-height: 125px;
  background-color: white;
  color: #477E7C;
}

/* Navigation styles */
.nav-container {
  text-align: left;
  min-height: 125px;
  padding: 10px 0;
}

.nav-list {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0;
  margin: 0;
}

.nav-item {
  list-style: none;
  margin: 0 15px;
}

.nav-button {
  cursor: pointer;
}

/* Styles for dropdown */
.dropdown {
  position: relative;
}

.dropdown-content {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  font-family: 'Helvetica', sans-serif;
  font-size: 18px;
  font-weight: bold;
}

.dropdown:hover .dropdown-content {
  display: block;
  color: #7AB988;
}

/* Nav button styles */
.btn-primary {
  background-color: white;
  color: #477E7C;
  border: none;
  font-family: 'Helvetica', sans-serif;
  font-size: 18px;
  font-weight: bold;
}

.btn-primary:hover {
  background-color: white;
  color: #7AB988;
}

.btn-primary:focus {
  background-color: lightgray;
  outline: none;
  box-shadow: none;
}

.btn-primary:active {
  background-color: white;
  color: #0B5351;
  outline: none;
  box-shadow: none;
}

.btn-light {
  background-color: white;
  color: #477E7C;
  border: none;
  font-family: 'Helvetica', sans-serif;
  font-size: 18px;
  font-weight: bold;
}

.btn-light:hover {
  background-color: white;
  color: #7AB988;
}

.btn-light:focus {
  background-color: lightgray;
  outline: none;
  box-shadow: none;
}

.btn-light:active {
  background-color: white;
  color: #0B5351;
  outline: none;
  box-shadow: none;
}


@media only screen and (max-width: 768px) {
  .Header {
    height: 125px;
  }
  #subtitle {
    font-size: 14px;
  }
  .Navigation ul li a {
    font-size: 16px;
  }
  .AboutMe,
  .Portfolio,
  .Contact {
    flex-direction: column;
  }
  .Contact > div {
    padding-right: 25px;
  }
}